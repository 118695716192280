export const usersList = [
  {
    id: 1,
    type: 'dancers',
    name: 'John Doe',
    certificationDate: '2022-01-15',
    photo: 'https://randomuser.me/api/portraits/men/1.jpg',
    level: 'B',
  },
  {
    id: 2,
    type: 'coaches',
    name: 'Jane Smith',
    certificationDate: '2021-11-05',
    photo: 'https://randomuser.me/api/portraits/women/2.jpg',
    level: 'A',
  },
  {
    id: 3,
    type: 'clubs',
    name: 'Dance Club Alpha',
    certificationDate: '2020-06-20',
    photo: 'https://randomuser.me/api/portraits/lego/1.jpg',
  },
  {
    id: 4,
    type: 'dancers',
    name: 'Alex Johnson',
    certificationDate: '2022-08-10',
    photo: 'https://randomuser.me/api/portraits/men/4.jpg',
    level: 'C',
  },
  {
    id: 5,
    type: 'coaches',
    name: 'Emily Davis',
    certificationDate: '2021-02-14',
    photo: 'https://randomuser.me/api/portraits/women/5.jpg',
    level: 'S',
  },
  {
    id: 6,
    type: 'clubs',
    name: 'Rhythm Central',
    certificationDate: '2019-12-12',
  },
  {
    id: 7,
    type: 'dancers',
    name: 'Michael Brown',
    certificationDate: '2023-04-03',
    level: 'D',
  },
  {
    id: 8,
    type: 'coaches',
    name: 'Linda Wilson',
    certificationDate: '2020-09-09',
    photo: 'https://randomuser.me/api/portraits/women/8.jpg',
    level: 'B',
  },
  {
    id: 9,
    type: 'clubs',
    name: 'Urban Beats',
    certificationDate: '2021-07-07',
    photo: 'https://randomuser.me/api/portraits/lego/2.jpg',
  },
  {
    id: 10,
    type: 'dancers',
    name: 'David Lee',
    certificationDate: '2022-05-25',
    photo: 'https://randomuser.me/api/portraits/men/10.jpg',
    level: 'E',
  },
  {
    id: 11,
    type: 'coaches',
    name: 'Sophia Moore',
    certificationDate: '2018-11-11',
    photo: 'https://randomuser.me/api/portraits/women/11.jpg',
    level: 'A',
  },
  {
    id: 12,
    type: 'clubs',
    name: 'Fusion Dance',
    certificationDate: '2017-08-23',
  },
  {
    id: 13,
    type: 'dancers',
    name: 'Chris Martinez',
    certificationDate: '2020-03-19',
    photo: 'https://randomuser.me/api/portraits/men/13.jpg',
    level: 'C',
  },
  {
    id: 14,
    type: 'coaches',
    name: 'Olivia Anderson',
    certificationDate: '2021-06-15',
    level: 'S',
  },
  {
    id: 15,
    type: 'clubs',
    name: 'Step It Up',
    certificationDate: '2019-02-28',
    photo: 'https://randomuser.me/api/portraits/lego/3.jpg',
  },
  {
    id: 16,
    type: 'dancers',
    name: 'Jessica Hernandez',
    certificationDate: '2023-01-05',
    photo: 'https://randomuser.me/api/portraits/women/16.jpg',
    level: 'B',
  },
  {
    id: 17,
    type: 'coaches',
    name: 'Daniel Thomas',
    certificationDate: '2020-04-14',
    level: 'E',
  },
  {
    id: 18,
    type: 'clubs',
    name: 'Groove Masters',
    certificationDate: '2018-10-31',
    photo: 'https://randomuser.me/api/portraits/lego/4.jpg',
  },
  {
    id: 19,
    type: 'dancers',
    name: 'Nancy Roberts',
    certificationDate: '2022-07-13',
    photo: 'https://randomuser.me/api/portraits/women/19.jpg',
    level: 'A',
  },
  {
    id: 20,
    type: 'coaches',
    name: 'James Clark',
    certificationDate: '2019-12-09',
    level: 'D',
  },
  {
    id: 21,
    type: 'clubs',
    name: 'Dance Revolution',
    certificationDate: '2017-05-22',
    photo: 'https://randomuser.me/api/portraits/lego/5.jpg',
  },
  {
    id: 22,
    type: 'dancers',
    name: 'Megan Walker',
    certificationDate: '2021-03-16',
    photo: 'https://randomuser.me/api/portraits/women/22.jpg',
    level: 'S',
  },
  {
    id: 23,
    type: 'coaches',
    name: 'Ethan Scott',
    certificationDate: '2018-07-27',
    photo: 'https://randomuser.me/api/portraits/men/23.jpg',
    level: 'B',
  },
  {
    id: 24,
    type: 'clubs',
    name: 'Beat Breakers',
    certificationDate: '2019-09-14',
  },
  {
    id: 25,
    type: 'dancers',
    name: 'Amanda Green',
    certificationDate: '2022-11-11',
    photo: 'https://randomuser.me/api/portraits/women/25.jpg',
    level: 'C',
  },
  {
    id: 26,
    type: 'coaches',
    name: 'Henry Martinez',
    certificationDate: '2020-01-22',
    level: 'F',
  },
  {
    id: 27,
    type: 'clubs',
    name: 'Rhythm Nation',
    certificationDate: '2017-12-20',
    photo: 'https://randomuser.me/api/portraits/lego/6.jpg',
  },
  {
    id: 28,
    type: 'dancers',
    name: 'Victoria Phillips',
    certificationDate: '2023-03-12',
    photo: 'https://randomuser.me/api/portraits/women/28.jpg',
    level: 'D',
  },
  {
    id: 29,
    type: 'coaches',
    name: 'William Gonzalez',
    certificationDate: '2019-11-01',
    level: 'E',
  },
  {
    id: 30,
    type: 'clubs',
    name: 'Dance Dynasty',
    certificationDate: '2020-07-30',
    photo: 'https://randomuser.me/api/portraits/lego/7.jpg',
  },
  {
    id: 31,
    type: 'judges',
    name: 'Sarah Williams',
    level: 'S',
    photo: 'https://randomuser.me/api/portraits/women/3.jpg',
  },
  {
    id: 32,
    type: 'judges',
    name: 'Jessica Jones',
    level: 'D',
    photo: 'https://randomuser.me/api/portraits/women/5.jpg',
  },
  { id: 33, type: 'judges', name: 'Robert Wilson', level: 'F', photo: 'https://randomuser.me/api/portraits/men/8.jpg' },
  {
    id: 34,
    type: 'judges',
    name: 'Barbara Anderson',
    level: 'S',
    photo: 'https://randomuser.me/api/portraits/women/11.jpg',
  },
  {
    id: 35,
    type: 'judges',
    name: 'Charles Harris',
    level: 'F',
    photo: 'https://randomuser.me/api/portraits/men/14.jpg',
  },
  {
    id: 36,
    type: 'judges',
    name: 'Elizabeth Lee',
    level: 'D',
    photo: 'https://randomuser.me/api/portraits/women/17.jpg',
  },
  {
    id: 37,
    type: 'judges',
    name: 'Richard Young',
    level: 'F',
    photo: 'https://randomuser.me/api/portraits/men/20.jpg',
  },
];
