export const importantKeywords = [
  'ECDS',
  'ProAm',
  'Dance',
  'Europe',
  'Open',
  'New',
  'Champion',
  'Championship',
  'World Cup',
  'DanceSport',
  'Ballroom',
  'Latin',
  'Street',
  'Caribbean',
  'Technique',
  'Judging',
  'Federation',
  'Council',
  'Training',
  'Coaching',
  'Innovation',
  'Mobile App',
  'Career Growth',
  'Performance',
  'Partnership',
  'Routine',
  'Competition',
  'Judges',
  'Signed',
];
