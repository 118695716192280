export const importantTags = [
  'Events',
  'Competitions',
  'Championships',
  'Training',
  'Results',
  'Workshops',
  'Technique',
  'Judges',
  'Coaches',
  'Youth Dance',
  'Awards',
  'Community',
  'Federation',
  'Regulations',
  'Innovations',
  'Trends',
  'Dancers',
  'Culture',
  'News',
  'Signatures',
  'Rules',
  'Innovation',
  'Exclusive',
  'Fashion',
];
