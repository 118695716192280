export const pathnames = {
  mainPage: '/',
  calendarPage: '/calendar',
  usersPage: '/users',
  certificationPage: '/certification',
  newsPage: '/news',
  membersPage: '/members',
  managementPage: '/management',
  documentsPage: '/documents',
  contactsPage: '/contacts',
  logosPage: '/logos',
  ballroomPage: '/ballroom-dance',
  streetDancePage: '/street-dance',
  caribbeanDancePage: '/caribbean-dance',
  constructionPage: '/construction',
  notFoundPage: '/not-found',
};
